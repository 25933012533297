import axios from '../../axios/axios'
import router from '../../router'

const applied_module = {
    namespaced: true,
    state: {
        resultApplied: [],
        status: 0,
        totalPage: {},
        pageState: 1,
        length_page: 1,
        isLength: false,

    },
    getters: {
        statusFetch(state) {
            return state
        },
        pagination(state) {
            return state
        }
    },
    mutations: {
        FETCH_APPLIED (state, payload) {
            state.resultApplied = payload
        },
        FETCH_HEADER_APPLIED (state, payload) {
            state.totalPage = payload
            if(payload.total == undefined || payload.per_page == undefined) {
                state.isLength = true
                state.pageState = payload.current_page
            } else {
                state.isLength = false
                state.pageState = payload.current_page
                state.length_page = Math.ceil(payload.total / payload.per_page)
            }
        },
        PAGINATION_APPLIED (state, payload) {
            if(payload.page !== undefined) state.pageState = payload.page
        }
    },
    actions: {
        async fetchApplied({ commit, getters }, payload) {
            if(payload) {
                commit('PAGINATION_APPLIED', payload)
            }
            await axios({
                method: "GET",
                url: `/merchants?status=${getters.statusFetch.status}&page=${getters.pagination.pageState}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ localStorage.getItem('adm_tkn')
                }
            })

            .then(({ data }) => {
                commit('FETCH_APPLIED', data.data.data)
                commit('FETCH_HEADER_APPLIED', data.data)
            })
            .catch((err) => {
                if(err.response.status == 401) {
                    localStorage.removeItem("adm_tkn");
                    return router.go('login')
                }
                commit('FETCH_APPLIED', [])
                commit('FETCH_HEADER_APPLIED', {})
            })
        },
        patchAllowDeny(context, payload) {
            axios({
                method: 'PATCH',
                url: `/merchants/${payload.id}/status-update?status=${payload.status}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ localStorage.getItem('adm_tkn')
                }
            })
            .then((res) => {
                context.dispatch('fetchApplied')
            })
            .catch((err) => {
                return err
            })
        }
    }
}

export default applied_module