import axios from "../../axios/axios";
import router from "../../router";
import Swal from 'sweetalert2'

const customer_module = {
  namespaced: true,
  state: {
    customerListResult: [],
    customerResult: {},
    customerPagination: {},
    email: "",
    name: "",
    phone: "",
    gender: "",
    bod: "",
    page: 1,
    is_length: false,
    length_page: null,
  },
  getters: {
    filtered(state) {
      return state;
    },
  },
  mutations: {
    FETCH_CUSTOMER_LIST(state, payload) {
      state.customerListResult = payload.data;
    },
    FETCH_PAGINATION(state, payload) {
      state.customerPagination = payload;
      if (payload.total == undefined || payload.per_page == undefined) {
        state.is_length = true;
        state.page = payload.current_page;
      } else {
        state.is_length = false;
        state.page = payload.current_page;
        state.length_page = Math.ceil(payload.total / payload.per_page);
      }
    },
    FETCH_CUSTOMER_DETAIL(state, payload) {
      state.customerResult = payload.data;
    },
    FILTERED_ORDER(state, payload) {
      if (payload.page !== undefined) state.page = payload.page;
      if (payload.email !== undefined) state.email = payload.email;
      if (payload.name !== undefined) state.name = payload.name;
      if (payload.phone !== undefined) state.phone = payload.phone;
      if (payload.gender !== undefined) state.gender = payload.gender;
      if (payload.bod !== undefined) state.bod = payload.bod;
    },
  },
  actions: {
    fetchCustomerList({ commit, getters }, payload) {
      if (payload) {
        commit("FILTERED_ORDER", payload);
      }

      const customers = axios({
        method: "GET",
        url: `/customers?page=${getters.filtered.page}&name=${getters.filtered.name}&phone=${getters.filtered.phone}&sex=${getters.filtered.gender}&bod=${getters.filtered.bod}&email=${getters.filtered.email}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_CUSTOMER_LIST", data.data);
          commit("FETCH_PAGINATION", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("adm_tkn");
            return router.go("login");
          }
            commit("FETCH_CUSTOMER_LIST", [])
            commit("FETCH_PAGINATION", {})
            return err.response.data;
        });
      return customers;
    },
    async fetchCustomerDetail(context, payload) {
      return await axios({
        method: "GET",
        url: `/customers/${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_CUSTOMER_DETAIL", data);
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    async updateCustomer(context, payload) {
      return await axios({
        method: "PATCH",
        url: `/customers/${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
        data: payload.form
      })
        .then(({ data }) => {
          router.push(`/`)
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: err.response.data.message,
            showConfirmButton: false,
            timer: 1000
          });
          return err.response.data;
        });
    },
  },
};
export default customer_module;
