import axios from '../../axios/axios'
import router from '../../router'
import Swal from "sweetalert2";

const approve_module = {
    namespaced: true,
    state: {
        resultApprove: [],
        status: 1,
        pic: "",
        company: "",
        address: "",
        phone: "",
        resultDetail: {},
        approveList_header: {},
        length_page: 1,
        isLength: false,
        pageState: 1,
        resultAllMerchant: []
    },
    getters: {
        statusFetch(state) {
            return state
        },
        filtered(state) {
            return state
        }
    },
    mutations: {
        FETCH_APPROVE (state, payload) {
            state.resultApprove = payload
        },
        FETCH_HEADER_APPROVE(state, payload) {
            state.approveList_header = payload
            if(payload.total == undefined || payload.per_page == undefined) {
                state.isLength = true
                state.pageState = payload.current_page
            } else {
                state.isLength = false
                state.pageState = payload.current_page
                state.length_page = Math.ceil(payload.total / payload.per_page)
            }
        },
        FILTER_APPROVE (state, payload) {
            if(payload.page !== undefined) state.pageState = payload.page
            if(payload.company !== undefined) state.company = payload.company
            if(payload.phone !== undefined) state.phone = payload.phone
            if(payload.pic !== undefined) state.pic = payload.pic
            if(payload.address !== undefined) state.address = payload.address
        },
        FETCH_DETAIL_APPROVE (state, payload) {
            state.resultDetail = payload
        },
        FETCH_ALL_MERCHANT(state, payload) {
            state.resultAllMerchant = payload
        }
    },
    actions: {
        async fetchApprove({commit, getters}, payload) {
            if(payload) {
                commit('FILTER_APPROVE', payload)
            }
            await axios({
                method: "GET",
                url: `/merchants?pic=${getters.filtered.pic}&company=${getters.filtered.company}&address=${getters.filtered.address}&status=${getters.statusFetch.status}&phone=${getters.filtered.phone}&page=${getters.filtered.pageState}`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + localStorage.getItem('adm_tkn')
                }
            })
            .then(({ data }) => {
                commit('FETCH_APPROVE', data.data.data)
                commit('FETCH_HEADER_APPROVE', data.data)
            })
            .catch((err) => {
                if(err.response.status == 401) {
                    localStorage.removeItem("adm_tkn");
                    router.go('login')
                }
                commit('FETCH_APPROVE', [])
                commit('FETCH_HEADER_APPROVE', {})
            })
        },
        async fetchDetailApprove(context, payload) {
            await axios({
                method: 'GET',
                url: `/merchants/${payload}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ localStorage.getItem('adm_tkn')

                }
            })
            .then(({ data }) => {
                context.commit('FETCH_DETAIL_APPROVE', data.data)
            })
            .catch((err) => {
                return err.response
            })
        },
        updateMerchant(context, payload) {
            axios({
                method: 'PUT',
                url: `/merchants/${payload.id}/update`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ localStorage.getItem('adm_tkn')
                },
                data: payload.data
            })
            .then((res) => {
                router.go(-1)
            })
            .catch((err) => {
                Swal.fire({
                    icon: 'warning',
                    position: "top",
                    text: err.response.data.message,
                    showConfirmButton: false,
                    timer: 1500
                })
                return err.response
            })
        },
        async fetchAllMechant(context, payload) {
            await axios({
                method: 'GET',
                url: `/merchants/list`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ localStorage.getItem('adm_tkn')
                }
            })
            .then((res) => {
                context.commit('FETCH_ALL_MERCHANT', res.data.data)
            })
            .catch((err) => {
                context.commit('FETCH_ALL_MERCHANT', [])
            })
        }
    }
}

export default approve_module