import axios from "../../axios/axios";
import router from "../../router";

const order_module = {
  namespaced: true,
  state: {
    resultOrder: [],
    resultOrderDetail: {},
    resultOrderHeader: {},
    resultShop: [],
    fMerchant: "",
    fShop: "",
    fOrderId: "",
    fStatus: "",
    fAmountTo: "",
    fAmountFrom: "",
    fDateFrom: "",
    fDateTo: "",
    page: 1,
    page_temp: null,
    is_length: false,
    length_page: null,
    orderDetail: {
      page: 1,
      length_page: 1,
      is_length: false,
    },
  },
  getters: {
    filtered(state) {
      return state;
    },
    orderDetail(state) {
      return state.orderDetail;
    },
  },
  mutations: {
    FETCH_ORDER_LIST(state, payload) {
      state.resultOrder = payload;
    },
    FETCH_ORDER_DETAIL(state, payload) {
      
      if(state.resultOrderDetail.orders !== undefined) {
        state.resultOrderDetail.orders.detail.data = []
      }
      state.resultOrderDetail = payload;
      if (
        payload.orders.detail.total == undefined ||
        payload.orders.detail.per_page == undefined
      ) {
        state.is_length = true;
        state.page = payload.orders.detail.current_page;
      } else {
        state.is_length = false;
        state.page = payload.orders.detail.current_page;
        state.orderDetail.length_page = Math.ceil(
          payload.orders.detail.total / payload.orders.detail.per_page
        );
      }
    },
    FILTER_ORDER_DETAIL(state, payload) {
      state.orderDetail.page = payload.page;
    },
    FILTERED_ORDER(state, payload) {
      if (payload.merchant !== undefined) state.fMerchant = payload.merchant;
      if (payload.shop !== undefined) state.fShop = payload.shop;
      if (payload.orderId !== undefined) state.fOrderId = payload.orderId;
      if (payload.status !== undefined) state.fStatus = payload.status;
      if (payload.dateFrom !== undefined) state.fDateFrom = payload.dateFrom;
      if (payload.dateTo !== undefined) state.fDateTo = payload.dateTo;
      if (payload.amountTo !== undefined) state.fAmountTo = payload.amountTo;
      if (payload.amountFrom !== undefined)
        state.fAmountFrom = payload.amountFrom;
      if (payload.page !== undefined) state.page = payload.page;
      state.page_temp = payload.page;
    },
    FETCH_ORDER_HEADER(state, payload) {
      state.resultOrderHeader = payload;
      if (payload.total == undefined || payload.per_page == undefined) {
        state.is_length = true;
        state.page = payload.current_page;
      } else {
        state.is_length = false;
        state.page = payload.current_page;
        state.length_page = Math.ceil(payload.total / payload.per_page);
      }
    },
    FETCH_SHOP(state, payload) {
      state.resultShop = payload;
    },
  },
  actions: {
    fetchOrderList({ commit, getters }, payload) {
      if (payload) {
        commit("FILTERED_ORDER", payload);
      }

      let orderList = axios({
        method: "GET",
        url: `/orders/list?page=${getters.filtered.page}&merchantId=${getters.filtered.fMerchant}&shop=${getters.filtered.fShop}&orderId=${getters.filtered.fOrderId}&status=${getters.filtered.fStatus}&amountfrom=${getters.filtered.fAmountFrom}&amountto=${getters.filtered.fAmountTo}&datefrom=${getters.filtered.fDateFrom}&dateto=${getters.filtered.fDateTo}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_ORDER_LIST", data.data.data);
          commit("FETCH_ORDER_HEADER", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("adm_tkn");
            return router.go("login");
          }
          commit("FETCH_ORDER_LIST", []);
          commit("FETCH_ORDER_HEADER", {});
          return err.response.data;
        });
      return orderList;
    },
    async fetchOrderDetail({ commit, getters }, payload) {
      if (payload) {
        commit("FILTER_ORDER_DETAIL", payload);
      }
      await axios({
        method: "GET",
        url: `/orders/${payload.id}?page=${getters.orderDetail.page}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_ORDER_DETAIL", data.data);
        })
        .catch((err) => {
          return err.response;
        });
    },
    fetchShop(context, payload) {
      axios({
        method: "GET",
        url: `/stores`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_SHOP", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_SHOP", []);
          return err.response;
        });
    },
  },
};

export default order_module;
