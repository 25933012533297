<template>
  <v-app>
    <SideBar :isLogin="getToken" />
    <v-main style="padding-top: 0px">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
export default {
  name: "App",
  components: {
    SideBar,
  },
  data: () => ({}),

  computed: {
    getToken() {
      return localStorage.getItem("adm_tkn");
    },
  },
  created() {
    // this.changeLocale();
  },

  methods: {
    // changeLocale() {
    //   this.$vuetify.lang.current = "ja";
    // },
  },
};
</script>

<style></style>
