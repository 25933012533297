import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { ja: "ログイン" },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/approvedMerchant",
    name: "ApprovedMerchant",
    meta: { ja: "加盟店" },
    component: () => import("../views/ApprovedMerchant.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/detailApproved/:id",
    name: "DetailApproved",
    meta: { ja: "加盟店詳細" },
    component: () => import("../views/DetailApproved.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/updateMerchant/:id/update",
    name: "UpdateMerchant",
    meta: { ja: "加盟店更新" },
    component: () => import("../views/UpdateMerchant.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/appliedMerchant",
    name: "AppliedMerchant",
    meta: { ja: "申請中" },
    component: () => import("../views/AppliedMerchant.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/",
    name: "CustomerManagement",
    meta: { ja: "顧客管理" },
    component: () => import("../views/CustomerManagement.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/customer/:id",
    name: "CustomerDetail",
    meta: { ja: "顧客の詳細" },
    component: () => import("../views/CustomerDetail.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/customer/:id/update",
    name: "CustomerUpdate",
    meta: { ja: "顧客の詳細" },
    component: () => import("../views/CustomerUpdate.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/lockerManagement",
    name: "LockerManagement",
    meta: { ja: "ロッカー管理" },
    component: () => import("../views/LockerManagement.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/addNewLocker",
    name: "AddNewLocker",
    meta: { ja: "ロッカーの追加" },
    component: () => import("../views/AddNewLocker.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },

  {
    path: "/editLocker",
    name: "EditLocker",
    meta: { ja: "ロッカーの編集" },
    component: () => import("../views/EditLocker.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/deliveryManagement",
    name: "DeliveryManagement",
    meta: { ja: "配送管理" },
    component: () => import("../views/DeliveryManagement.vue"),
  },
  {
    path: "/orderManagement",
    name: "OrderManagement",
    meta: { ja: "注文管理" },
    component: () => import("../views/OrderManagement.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/orderDetail/:id",
    name: "OrderDetail",
    meta: { ja: "注文詳細" },
    component: () => import("../views/OrderDetail.vue"),
    // beforeEnter: (to, from, next) => {
    //   if (!localStorage.adm_tkn) {
    //     next({ name: "Login" });
    //   } else if (localStorage.adm_tkn && localStorage.role != 1){
    //     next({ name: "DeliveryManagement" });
    //   }else {
    //     next();
    //   }
    // },
  },
  {
    path: "/categoryMaster",
    name: "CategoryMaster",
    meta: { ja: "カテゴリマスター" },
    component: () => import("../views/CategoryMaster"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/addCategory",
    name: "AddCategory",
    meta: { ja: "カテゴリの追加" },
    component: () => import("../views/AddCategory.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/editCategory/:id",
    name: "EditCategory",
    meta: { ja: "カテゴリの編集" },
    component: () => import("../views/EditCategory.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/productManagement",
    name: "ProductManagement",
    meta: { ja: "製品管理" },
    component: () => import("../views/ProductManagement.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/productDetail/:slug",
    name: "ProductDetail",
    meta: { ja: "製品の詳細" },
    component: () => import("../views/ProductDetail.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/lockerDetail",
    name: "LockerDetail",
    meta: { ja: "ロッカー詳細" },
    component: () => import("../views/LockerDetail.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/salesOrderDetails",
    name: "SalesOrderDetails",
    meta: { ja: "注文内容" },
    component: () => import("../views/SalesOrderDetails.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/salesOrders",
    name: "SalesOrderList",
    meta: { ja: "受注リスト" },
    component: () => import("../views/SalesOrderList.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/schedule/",
    name: "ScheduleMenu",
    meta: { ja: "献立管理" },
    component: () => import("../views/ScheduleMenu.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/schedule/edit/:date",
    name: "EditSchedule",
    meta: { ja: "献立編集" },
    component: () => import("../views/EditSchedule.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/config",
    name: "ConfigList",
    meta: { ja: "設定" },
    component: () => import("../views/config/ConfigList.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/config/create",
    name: "CreateConfig",
    meta: { ja: "設定追加" },
    component: () => import("../views/config/CreateConfig.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/config/:id",
    name: "ConfigDetail",
    meta: { ja: "設定詳細" },
    component: () => import("../views/config/ConfigDetail.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "/config/:id/update",
    name: "UpdateConfig",
    meta: { ja: "設定編集" },
    component: () => import("../views/config/UpdateConfig.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },

  {
    path: "/tajimiInfo/create",
    name: "CreateTajimiInfo",
    meta: { ja: "特定商取引法に基づく表記" },
    component: () => import("../views/about/CreateTajimiInfo.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.adm_tkn) {
        next({ name: "Login" });
      } else if (localStorage.adm_tkn && localStorage.role != 1) {
        next({ name: "DeliveryManagement" });
      } else {
        next();
      }
    },
  },
  {
    path: "*",
    name: "NotFound",
    meta: { ja: "見つかりません" },
    component: () => import("../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // base: '/front-admin',
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${
    to.name == null ? "Home" : to.name
  }`;
  if (process.env.VUE_APP_I18N_LOCALE == "ja") {
    document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.ja}`;
  }
  // next()
  // if (to.name === '/' && localStorage.adm_tkn) {
  //   next({name: 'CustomerManagement'})
  // }
  if (to.name === "Login" && localStorage.adm_tkn && localStorage.role != 1) {
    next({ name: "DeliveryManagement" });
  } else if (
    to.name === "Login" &&
    localStorage.adm_tkn &&
    localStorage.role == 1
  ) {
    next({ name: "CustomerManagement" });
  } else if (to.name !== "Login" && !localStorage.adm_tkn) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
