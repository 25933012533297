import axios from "../../axios/axios";
import router from "../../router";
import Swal from 'sweetalert2'

const customer_module = {
  namespaced: true,
  state: {
    productsResult: [],
    productDetailResult: {},
    productPagination: {},
    merchant: "",
    shop: "",
    productName: "",
    category: "",
    priceStart: "",
    priceEnd: "",
    page: 1,
    length_page: 1,
    isLength: false,
    perpage: 10
  },
  getters: {
    filtered(state) {
      return state;
    },

  },
  mutations: {
    FETCH_PRODUCT_LIST(state, payload) {
      state.productsResult = payload.data;
    },
    FETCH_PRODUCT_DETAIL(state, payload) {
      state.productDetailResult = payload.item;
    },
    FILTERED_PRODUCT(state, payload) {

      if (payload.page) state.page = payload.page
      if (payload.perpage !== undefined) state.perpage = payload.perpage;
      if (payload.merchant !== undefined) state.merchant = payload.merchant;
      if (payload.shop !== undefined) state.shop = payload.shop;
      if (payload.productName !== undefined)
        state.productName = payload.productName;
      if (payload.category !== undefined) state.category = payload.category;
      if (payload.priceStart !== undefined) state.priceStart = payload.priceStart;
      if (payload.priceEnd !== undefined) state.priceEnd = payload.priceEnd;
    },
    FETCH_PRODUCT_HEADER(state, payload){
      state.productPagination = payload
      if(payload.total == undefined || payload.per_page === undefined) {
        state.page = payload.current_page
        state.isLength = true
      } else {
        state.isLength = false
        state.page = payload.current_page
        state.length_page = Math.ceil(payload.total / payload.per_page)
      }
    }
  },
  actions: {
    fetchProductList({ commit, getters }, payload) {
      if (payload) {
        commit("FETCH_PRODUCT_LIST", [])
        commit("FILTERED_PRODUCT", payload);
      }
      const products = axios({
        method: "GET",
        url: `/items?perpage=${getters.filtered.perpage}&page=${getters.filtered.page}&merchant=${getters.filtered.merchant}&shop=${getters.filtered.shop}&item=${getters.filtered.productName}&category=${getters.filtered.category}&priceStart=${getters.filtered.priceStart}&priceEnd=${getters.filtered.priceEnd}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_PRODUCT_LIST", data.data);
          commit("FETCH_PRODUCT_HEADER", data.data)
        
        })
        .catch((err) => {
          if (err.response.status == 401) {
              localStorage.removeItem("adm_tkn");
              return router.go('login')
          }
          commit("FETCH_PRODUCT_LIST", []);
          commit("FETCH_PRODUCT_HEADER", {})
          return err.response.data;
        });
      return products;
    },
    async fetchProductDetail({ commit, getters }, payload) {
      
      axios({
        method: "GET",
        url: "/items/p/"+payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("adm_tkn"),
        },
      })
        .then(({ data }) => {
          commit("FETCH_PRODUCT_DETAIL", data.data);
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    deleteProductList(context, payload) {
      axios({
        method: 'GET',
        url: `/items/delete/${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("adm_tkn"),
        },
      })
      .then((res) => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          },
        })
        Toast.fire({
          icon: 'success',
          title: payload.message.title,
        })
        context.dispatch('fetchProductList')
      })
      .catch((err) => {
        return err.response
      })
    }
  },
};
export default customer_module;
