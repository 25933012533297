import axios from "../../axios/axios";
import router from "../../router";
import Swal from 'sweetalert2'
import { mdiUpload } from "@mdi/js";

const category_module = {
  namespaced: true,
  state: {
    categoryDetail: {},
    categoryList: [],
    categoryParent: [],
    categoryFilter: {
      categoryId: "",
      statusId: "",
      parentCategoryId: '',
      name: '',
      page: 1,
      perpage: 10
    },
    page: 1,
    is_length: false,
    length_page: null,
    categoryHeader: {},
    categoryAll: [],
    imageLink: ""
  },
  getters: {
    categoryFilter(state) {
      return state.categoryFilter;
    },
  },
  mutations: {
    FETCH_CATEGORYLIST(state, payload) {
      state.categoryList = payload;
    },
    FETCH_CATEGORY_DETAIL(state, payload) {
      state.categoryDetail = payload
    },
    FETCH_CATEGORYPARENT(state, payload) {
      state.categoryParent = payload;
    },
    SET_CATEGORYFILTER(state, payload) {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          state.categoryFilter[key] = payload[key];
        }
      }
    },
    FETCH_CATEGORYHEADER(state, payload) {
      state.categoryHeader = payload
      if (payload.total == undefined || payload.per_page == undefined) {
        state.is_length = true;
        state.page = payload.current_page;
      } else {
        state.is_length = false;
        state.page = payload.current_page;
        state.length_page = Math.ceil(payload.total / payload.per_page);
      }
    },
    FILTERED_CATEGORY(state, payload) {
      if (payload.categoryId !== undefined) state.categoryFilter.categoryId = payload.categoryId
      if (payload.statusId !== undefined) state.categoryFilter.statusId = payload.statusId
      if (payload.parentCategoryId !== undefined) state.categoryFilter.parentCategoryId = payload.parentCategoryId
      if (payload.name !== undefined) state.categoryFilter.name = payload.name
      if (payload.page !== undefined) state.categoryFilter.page = payload.page
      if (payload.perpage !== undefined) state.categoryFilter.perpage = payload.perpage
    },
    FETCH_ALLCATEGORY(state, payload) {
      state.categoryAll = payload
    },
    FETCH_IMAGELINK(state, payload) {
      state.imageLink = payload.image_url
    }
  },
  actions: {
    fetchCategoryList({commit, getters}, payload) {
      if (payload) {
        commit('FILTERED_CATEGORY', payload)
      }
      let filter = getters.categoryFilter

      let resp = axios({
        url: `/categories/list?page=${filter.page}&status=${filter.statusId}&id=${filter.categoryId}&name=${filter.name}&parent=${filter.parentCategoryId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
        data: {},
      })
        .then(({ data }) => {
          commit("FETCH_CATEGORYLIST", data.data.data);
          commit("FETCH_CATEGORYHEADER", data.data);
        })
        .catch((err) => {
          if(err.response.status == 401) {
            localStorage.removeItem("adm_tkn");
            return router.go('login')
          }
          commit("FETCH_CATEGORYLIST", []);
          commit("FETCH_CATEGORYHEADER", {});
        });

      return resp;
    },
    fetchCategoryDetail(context, payload) {
      let resp = axios({
        url: `/categories/${payload}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_CATEGORY_DETAIL", data.data);
          context.commit("FETCH_IMAGELINK", { image_url: data.data.image_url });
        })
        .catch((err) => {
          return err.response
        });

      return resp;
    },
    
    // HIDE TEMPORARY
    // fetchParentCategory(context, payload) {
    //   let resp = axios({
    //     url: "/categories/all",
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
    //     },
    //     data: {},
    //   })
    //     .then(({ data }) => {
    //       context.commit("FETCH_CATEGORYPARENT", data.data);
    //     })
    //     .catch((err) => {
    //       return err.response
    //     });

    //   return resp;
    // },
    addCategory(context, payload) {
      let resp = axios({
        url: "/categories/add",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
        data: payload.payload,
      })
        .then(({ data }) => {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
          })
          Toast.fire({
            icon: 'success',
            title: payload.message.title,
          })
          return data;
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            text: err.response.data.message,
          })
          return err.response;
        });

      return resp;
    },
    searchCategory({ commit, getters }, payload) {
      let resp = axios({
        url: `/categories/list?status=${getters.categoryFilter.statusId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
        data: {},
      })
        .then(({ data }) => {
          commit("FETCH_CATEGORYLIST", data.data.data);
        })
        .catch((err) => {
          return err.response
        });

      return resp;
    },
    setCategoryFilter(contex, payload) {
      contex.commit("SET_CATEGORYFILTER", payload);
    },
    updateCategory({ commit, getters }, payload) {
      axios({
        url: `/categories/update/${payload.id}`,
        method: "PUT",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
        data: payload.data
      })
        .then(({ data }) => {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
          })
          Toast.fire({
            icon: 'success',
            title: payload.message.title
          })
        })
        .catch((err) => {
          return err.response
        });
    },
    deleteCategory(context, payload){
      axios({                
        url: `/categories/delete/${payload.id}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("adm_tkn")}`,
        }
      })
      .then(res => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          },
        })
        Toast.fire({
          icon: 'success',
          title: payload.message.title,
        })
        context.dispatch('fetchCategoryList')
      })
      .catch(err => {
        return err.response
      })
    },
    fetchAllCategory(context) {
      axios({
        url: '/categories/all',
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("adm_tkn")}`,
        }

      })
      .then(({ data }) => {
        context.commit('FETCH_ALLCATEGORY', data.data)
      })
      .catch((err) => {
        context.commit('FETCH_ALLCATEGORY', [])
      })
    },
    async upload(context, payload) {
      let formData = new FormData();

      formData.append("file", payload.images);
      formData.append("path", payload.path);

      await axios({
        method: "POST",
        url: "/upload",
        headers: {
          // 'Authorization': 'Bearer '+ localStorage.getItem('mrchnt_tkn'),
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
        data: formData,
      })
      .then(({ data }) => {
          context.commit("FETCH_IMAGELINK", { image_url: data.data.file });
      })
      .catch((err) => {
          return err.response
      });
    },
    clearImg(context) {
      context.commit("FETCH_IMAGELINK", { image_url: null });
    }
  },
};

export default category_module;
