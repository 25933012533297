import axios from "../../axios/axios";
import router from "../../router";
import Swal from "sweetalert2";
import i18n from "../../i18n";

const auth_module = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    login(context, payload) {
      let dataLogin = axios({
        url: "/login",
        method: "POST",
        data: {
          email: payload.email,
          password: payload.password,
        },
      })
        .then(({ data }) => {
          if (data.data) {
            localStorage.setItem("adm_tkn", data.data.token);
            localStorage.setItem("role", data.data.role_id);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: i18n.t("message.success-login"),
            })
            router.go("/");
          } else {
            Swal.fire({
              icon: "error",
              text:  i18n.t("message.failed-login"),
            });
          }
        })
        .catch((err) => {
          return err.response;
        });
      return dataLogin;
    },

    logout() {
      axios({
        url: "/logout",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then((res) => {
          localStorage.removeItem("adm_tkn");
          localStorage.removeItem("role");
          router.go("/login");
        })
        .catch((err) => {
          return err;
        });
    },
  },
};

export default auth_module;
