import axios from "../../axios/axios";
import router from "../../router";

const shop_module = {
  namespaced: true,
  state: {
    shopResult: [],
  },
  getters: {
  },
  mutations: {
    FETCH_SHOP_LIST(state, payload) {
      state.shopResult = payload;
    },
  },
  actions: {
    fetchShopList(context, payload) {
      const products = axios({
        method: "GET",
        url: `/stores`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_SHOP_LIST", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("adm_tkn");
            return router.go('login')
          }
          return err.response.data;
        });
      return products;
    },
  },
};
export default shop_module;
