import axios from '../../axios/axios'
import Swal from "sweetalert2";

const about_module = {
    namespaced: true,
    state: {
        resultPlatformInfo: {}
    },  
    mutations: {
        FETCH_PLATFORM_INFO(state, payload) {
            state.resultPlatformInfo = payload
        }
    },
    actions: {
        async fetchPlatformInfo(context, payload) {
            await axios({
                method: "GET",
                url: '/platforminfo',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("adm_tkn")}`,
                },
            })
            .then(({ data }) => {
                context.commit("FETCH_PLATFORM_INFO", data.data)
            })
            .catch((err) => {
                return err.response.data.message
            })
        },
        createTajimiInfo(context, payload) {
            axios({
                method: 'PATCH',
                url: '/platforminfo',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("adm_tkn")}`,
                },
                data: {
                    ops_manager: payload.pic,
                    phone: payload.phone,
                    fax: payload.fax,
                    location: payload.address,
                    url: payload.url,
                    email: payload.email,
                    necessary_price: payload.price,
                    payment_method: payload.payment_method,
                    due_date_payment: payload.payment_date,
                    delivery_time: payload.delivery_time,
                    how_to_order: payload.order,
                    about_goods: payload.return_and_exchange
                }
            })
            .then(({ data }) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top",
                    showConfirmButton: false,
                    timer: 1000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  });
                  Toast.fire({
                    icon: "success",
                    title: data.message
                  })
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    text:  err.response.data.message
                });
                return err.response.message
            })
        }
    }
}

export default about_module