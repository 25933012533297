import router from "../../router";
import axios from "../../axios/axios";

const sales_module = {  
    namespaced: true,
    state: {
        salesResult: [],
        salesOrders: []
    },
    mutations: {
        FETCH_SALES(state, payload) {
            state.salesResult = payload
        },
        FETCH_SALES_ORDERS(state, payload) {
            state.salesOrders = payload
        }
    },
    actions: {
        async fetchSalesOrderDetails({ getters, commit }, payload) {
            await axios({
                method: "GET",
                url: `/report/orderDetails`,
                params: payload,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("adm_tkn")}`,
                }
            })
            .then(({ data }) => {
                // console.log(data.data);
                commit("FETCH_SALES", data.data)
            })
            .catch((err) => {
                if(err.response.status == 404) {
                    commit("FETCH_SALES", [])
                }
                return err
            })
        },
        async fetchSalesOrders({ getters, commit }, payload) {
            await axios({
                method: "GET",
                url: `/report/orders`,
                params: payload,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("adm_tkn")}`,
                }
            })
            .then(({ data }) => {
                commit("FETCH_SALES_ORDERS", data.data)
            })
            .catch((err) => {
                if(err.response.status == 404) {
                    commit("FETCH_SALES_ORDERS", [])
                }
                return err
            })
        },
        async fetchExportCsv(context, payload) {
            await axios({
                method: "GET",
                url: `/report/exportOrderDetails`,
                params: payload,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("adm_tkn")}`,
                },
                responseType: "blob"
            })
            .then((res) => {
                const url = URL.createObjectURL(
                    new Blob([res.data], {
                        type: "application/vnd.ms-excel"
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                const filename = '売上.csv';
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click()
            })
            .catch((err) => {
                return err
            })
        },
        async fetchExportCsvSalesOrders(context, payload) {
            await axios({
                method: "GET",
                url: `/report/exportOrders`,
                params: payload,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("adm_tkn")}`,
                },
                responseType: "blob"
            })
            .then(({ data }) => {
                const url = URL.createObjectURL(
                    new Blob([data], {
                        type: "application/vnd.ms-excel"
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                const filename = '売上.csv';
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click()
            })
            .catch((err) => {
                return err
            })
        }
    }
}

export default sales_module