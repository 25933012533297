import axios from "../../axios/axios";
import router from "../../router";
// import { addDays } from "date-fns";
import getEventIndex from "../../helpers/findIndex";
import Swal from "sweetalert2";
import i18n from "../../i18n";

const schedule_module = {
  namespaced: true,
  state: {
    menus: [],
    foods: [],
    storeListSchedule: [],
    menu: {},
    qty: []
  },
  getters: {
    events: (state) => state.events,
    getMenu: (state) => {
      return state.menus.map(({ id, menu_date, item_name }) => ({
        id: id,
        title: item_name,
        date: menu_date,
        allDay: true,
      }));
    },
  },
  mutations: {
    CREATE_EVENT(state, event) {
      return state.events.push(event);
    },
    DELETE_EVENT(state, eventId) {
      let index = getEventIndex(state, eventId);
      if (index === -1) {
        return console.warn(`Unable to delete event (id ${eventId})`);
      }
      return state.events.splice(index, 1);
    },
    CREATE_MENU(state, payload) {
      state.menus = payload;
    },
    GET_MENU(state, payload) {
      if (payload.data){
        state.foods = payload.data
      } 
      else state.foods = [];
    },
    GET_QTY(state, payload) {
      if (payload.qty){
        state.qty = payload.qty
      } 
      else state.qty = [];
    },
    DETAIL_MENU(state, payload) {
      state.menu = payload;
    },
    FETCH_STORE_SCHEDULE(state, payload) {
      state.storeListSchedule = payload
    }
  },

  actions: {
    createEvent({ commit }, event) {
      return commit("CREATE_EVENT", event);
    },
    deleteEvent({ commit }, eventId) {
      return commit("DELETE_EVENT", eventId);
    },
    async getMenu({ getters, commit }, shopId) {
      return await axios({
        method: "GET",
        url: `/menuschedule/listItem/${shopId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("GET_MENU", data);
        })
        .catch((err) => commit("GET_MENU", []));
    },
    async getQty({ getters, commit }, itemId) {

      return await axios({
        method: "GET",
        url: `/menuschedule/itemQty?idItem=${itemId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("GET_QTY", data);
        })
        .catch((err) => commit("GET_MENU", []));
    },
    async createMenu({ commit }, payload) {
      let data = await axios({
        method: "GET",
        url: `/menuschedule`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("CREATE_MENU", data.data);
          return data.data.data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    },
    async detailMenu({ getters, commit }, payload) {
      return await axios({
        method: "GET",
        url: `menuschedule/itemByDate/${payload}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("DETAIL_MENU", data.data);
        })
        .catch((err) => {
          commit("DETAIL_MENU", []);
        });
    },
    async updateMenu({ commit }, payload) {
      return await axios({
        method: "PUT",
        url: `/menuschedule`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
        data: payload,
      })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          
          return err;
        });
    },
    deleteMenu({ commit }, payload) {
      return axios({
        method: "GET",
        url: `/menuschedule/delete/${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err;
        });
    },
    publish({ commit }, payload) {
      return axios({
        method: "POST",
        url: `/menuschedule/publish`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
        data: payload,
      })
        .then((res) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: false,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: i18n.t("message.publish-success"),
          });
        })
        .catch((err) => {
          // return err;
        });
    },
    storeListSchedule(context, payload) {
      axios({
        method: "GET",
        url: '/menuschedule/listStore',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
      .then(({ data }) => {
        context.commit("FETCH_STORE_SCHEDULE", data.data)
      })
      .catch((err) => {
        return err.response.message
      })
    }
  },
};
export default schedule_module;
