import Vue from "vue";
import Vuex from "vuex";
import auth_module from './modules/auth_module'
import category_module from "./modules/category_module";
import customer_module from './modules/customer_module'
import approve_module from './modules/approve_module'
import applied_module from './modules/applied_module'
import order_module from './modules/order_module'
import product_module from './modules/product_module'
import shop_module from './modules/shop_module'
import delivery_module from '@/store/modules/delivery_module'
import schedule_module from '@/store/modules/schedule_module'
import config_module from '@/store/modules/config_module'
import about_module from './modules/about_module'
import sales_module from './modules/sales_module'



Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth_module,
    customer_module,
    category_module,
    approve_module,
    applied_module,
    order_module,
    product_module,
    shop_module,
    delivery_module,
    schedule_module,
    config_module,
    about_module,
    sales_module
  }
})


