<template>
  <nav>
    <v-toolbar v-if="isMobile" flat>
      <v-app-bar color="indigo" dark app>
        <v-app-bar-nav-icon @click.stop="openDrawer"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
      </v-app-bar>
    </v-toolbar>

    <v-navigation-drawer
      v-if="isLogin"
      :permanent="$vuetify.breakpoint.mdAndUp"
      v-model="drawer"
      color="indigo"
      :width="320"
      dark
      app
    >
      <div
        v-for="list in drawerList"
        :key="list.text"
        router
        :value="list.active"
        :to="list.route"
      >
        <v-list-group v-if="list.subitems" no-action>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-text="list.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="list-item-title-multiple"
                  v-text="list.text"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="subItem in list.subitems"
            :key="subItem.text"
            :to="subItem.route"
            active-class="active"
          >
            <v-list-item-content class="list-item-subcontent">
              <v-list-item-title
                class="list-item-title-subcontent"
                v-text="subItem.text"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-else :to="list.route" active-class="active" class="margin-icon">
          <v-list-item-icon >
            <v-icon v-text="list.icon" ></v-icon>
          </v-list-item-icon>
          <v-list-item-content style="padding-top:0px; padding-bottom: 0px;">
            <v-list-item-title >{{ list.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <!-- LOGOUT/LOGIN-->
      <div v-if="isLogin">
        <v-list-item active-class="active">
          <v-list-item-icon >
            <v-icon v-text="mdiLogout" ></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title @click.prevent="handleLogout">
              <a
                style="text-decoration: none; color:white; font-size: 16px"
                text
                class="mt-5"
                color="white"
              >
                {{ $t("message.sidebar-logout") }}
              </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import {
  mdiAccount,
  mdiBasket,
  mdiStorefrontOutline,
  mdiApplicationCog,
  mdiMenu,
  mdiShopping,
  mdiMoped,
  mdiSourceBranch,
  mdiLogout,
  mdiCalendarMultiselect ,
  mdiCogOutline,
  mdiInformationVariant,
  mdiCardBulletedSettings
} from "@mdi/js";
import LangSwicther from "./LangSwitcher.vue";

export default {
  name: "Sidebar",
  components: {
    LangSwicther,
  },
  data: () => ({

    drawer: true,
    isMobile: true,
    mdiLogout,
  }),
  methods: {
    openDrawer() {
      this.drawer = !this.drawer;
    },
    handleview() {
      this.isMobile = window.innerWidth <= 960;
    },
    handleLogout() {
      this.$store.dispatch("auth_module/logout");
    },
  },
  mounted() {
    this.handleview();
    window.addEventListener("resize", this.handleview, { passive: true });
  },
  computed: {
    drawerList() {
      let role = localStorage.getItem("role")
      if (role == 1) {
        return [
          { icon: mdiMenu, text: this.$t("message.sidebar-menu") },
          {
            icon: mdiStorefrontOutline,
            text: this.$t("message.title-merchant"),
            subitems: [
              {
                text: this.$t("message.title-approved-merchant"),
                route: "/approvedMerchant",
              },
              {
                text: this.$t("message.title-applied-merchant"),
                route: "/appliedMerchant",
              },
            ],
          },
          {
            icon: mdiAccount,
            text: this.$t("message.title-customer"),
            route: "/",
          },
          {
            icon: mdiMoped,
            text: this.$t("message.title-delivery"),
            route: "/deliveryManagement",
          },
          {
            icon: mdiBasket,
            text: this.$t("message.title-order"),
            route: "/orderManagement",
          },
          {
            icon: mdiSourceBranch,
            text: this.$t("message.title-category"),
            route: "/categoryMaster",
          },
          {
            icon: mdiApplicationCog,
            text: this.$t("message.title-product"),
            route: "/productManagement",
          },
          {
            icon: mdiCardBulletedSettings,
            text: this.$t("message.sales"),
            subitems: [
              {
                text: this.$t("message.orders-list"),
                route: '/salesOrders'
              },
              {
                text: this.$t("message.order-details"),
                route: '/salesOrderDetails'
              },
            ]
          },
          {
            icon: mdiCalendarMultiselect,
            text: this.$t("message.schedule"),
            route: "/schedule",
          },
          {
            icon: mdiCogOutline,
            text: this.$t("message.config"),
            route: "/config",
          },
          {
            icon: mdiInformationVariant,
            text: this.$t("message.title-about"),
            subitems: [
              {
                text: this.$t("message.title-tajimiInfo"),
                route: '/tajimiInfo/create'
              }
            ]
          }
        ];
      } else {
        return [
          { icon: mdiMenu, text: this.$t("message.sidebar-menu") },
          {
            icon: mdiMoped,
            text: this.$t("message.title-delivery"),
            route: "/deliveryManagement",
          },
        ];
      }
    },
  },
  props: {
    isLogin: null,
  },
};
</script>

<style>
label {
  display: inline-block;
}
label > input {
  /* HIDE RADIO */
  visibility: hidden; /* Makes input not-clickable */
  position: absolute; /* Remove input from document flow */
}
label > input + img {
  /* IMAGE STYLES */
  cursor: pointer;
  border: 2px solid transparent;
}
label > input:checked + img {
  /* (RADIO CHECKED) IMAGE STYLES */
  border: 2px solid #f00;
}
.toolbar-class .v-toolbar__content {
  height: 98px !important;
}
.v-list-item__icon {
  margin-left: 5px !important;
  justify-content: start !important;
}
.active {
  border-right: 10px solid #1a237e;
}
a div .v-list-item__title {
  color: white;
}
.v-application a {
  color: #1a237e;
}
.v-icon__svg {
  color: white;
}
.v-list-item--active {
  color: #1a237e;
}
.v-list-group__header div .mdi-chevron-down {
  color: white;
}
.v-navigation-drawer__content div a {
  color: transparent;
  font-size: 9px;
}
.v-list-group__header .v-list-item {
  padding-left: 0px;
}
.list-item-title-multiple {
  font-size: 15px;
}
.list-item-title-single {
  font-size: 15px;
  /* padding-top: 10px; */
}
.list-item-subcontent {
  margin-left: 10px;
  /* padding-top: 0px; */
  padding-bottom: 0px;
}
.list-item-title-subcontent {
  font-size: 15px;
  margin-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 18px;
}
</style>
