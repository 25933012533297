<template>
  <div>
    <ul>
      <li v-for="language in lang" :key="language" @click="switchLang(language)">
        {{language}}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LangSwicther',
  data(){
    return {
      lang: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    }
  },
  methods: {
    switchLang(locale){
      if (this.$i18n.locale !== locale){
        this.$i18n.locale = locale
      }
    }
  }
}
</script>

<style>
li {
  text-decoration: none;
  /* color: white; */
  cursor: pointer;
};

</style>