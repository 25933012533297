import axios from "../../axios/axios";
import router from "../../router";

const config_module = {
  namespaced: true,
  state: {
    configList: [],
    configDetail: {},
  },
  getters: {},
  mutations: {
    FETCH_CONFIG_LIST(state, payload) {
      if (payload != undefined) state.configList = payload;
    },
    FETCH_CONFIG_DETAIL(state, payload) {
      if (payload != undefined) state.configDetail = payload;
    },
  },
  actions: {
    fetchConfigList({ commit, getters }, payload) {
      let res = axios({
        method: "GET",
        url: `/config`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_CONFIG_LIST", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("adm_tkn");
            return router.go("login");
          }
          commit("FETCH_CONFIG_LIST", []);
          return err.response.data;
        });
      return res;
    },
    async fetchConfigDetail({ commit, getters }, payload) {
      return await axios({
        method: "GET",
        url: `/config/${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_CONFIG_DETAIL", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("adm_tkn");
            return router.go("login");
          }
          commit("FETCH_CONFIG_DETAIL", {});
          return err.response.data;
        });
    },
    updateConfig({ commit, getters }, payload) {
      axios({
        method: "PATCH",
        url: `/config/${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
        data: payload.form
      })
        .then(({ data }) => {
          router.push('/config')
          // commit("FETCH_CONFIG_UPDATE", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("adm_tkn");
            return router.go("login");
          }
          commit("FETCH_CONFIG_UPDATE", {});
          return err.response.data;
        });
    },
    createConfig({ commit, getters }, payload) {
      axios({
        method: "POST",
        url: `/config`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
        data: payload.form
      })
        .then(({ data }) => {
          router.push('/config')
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("adm_tkn");
            return router.go("login");
          }
          return err.response.data;
        });
    },
  },
};

export default config_module;
