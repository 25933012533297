import axios from "../../axios/axios";
import Swal from "sweetalert2";
import router from "../../router";

const delivery_module = {
  namespaced: true,
  state: {
    deliveryListResult: [],
    deliveryHeaderResult: {},
    page: 1,
    is_length: false,
    length_page: null,
    status: "",
    dateFrom: "",
    dateTo: "",
    reqFrom: "",
    reqTo: "",
    delivery_date: "",
    // orderDate: ["", ""],
    // requestDate: ["",""]
    print: {
      status: "",
      reqFrom: "",
      reqTo: "",
      dateFrom: "",
      dateTo: "",
      delivery_date: "",
    },
  },
  getters: {
    filtered(state) {
      // return state.filtered
      return state;
    },
    print(state) {
      return state.print;
    },
  },
  mutations: {
    FETCH_DELIVERY_LIST(state, payload) {
      state.deliveryListResult = payload;
    },
    FETCH_DELIVERY_HEADER(state, payload) {
      state.deliveryHeaderResult = payload;
      if (payload.total == undefined || payload.per_page == undefined) {
        state.is_length = true;
        state.page = payload.current_page;
      } else {
        state.is_length = false;
        state.page = payload.current_page;
        state.length_page = Math.ceil(payload.total / payload.per_page);
      }
    },
    FILTERED_DELIVERY(state, payload) {
      if (payload.status !== undefined) state.status = payload.status;
      if (payload.orderDatesFrom !== undefined)
        state.dateFrom = payload.orderDatesFrom;
      if (payload.orderDatesTo !== undefined)
        state.dateTo = payload.orderDatesTo;
      if (payload.reqDateFrom !== undefined)
        state.reqFrom = payload.reqDateFrom;
      // if (payload.reqDateTo !== undefined) state.reqTo = payload.reqDateTo;
      if (payload.page !== undefined) state.page = payload.page;
      if (payload.delivery_date !== undefined)
        state.delivery_date = payload.delivery_date;
    },
    CLEAR_FILTER_ORDER(state) {
      state.status = "";
      state.page = "";
      state.dateFrom = "";
      state.dateTo = "";
      state.reqFrom = "";
      state.reqTo = "";
    },
    PRINT_DELIVERY(state, payload) {
      state.print.status = payload.status == undefined ? "" : payload.status;
      // state.print.reqFrom = payload.reqFrom == undefined ? "" : payload.reqFrom;
      // state.print.reqTo = payload.reqTo == undefined ? "" : payload.reqTo;
      state.print.dateFrom =
        payload.dateFrom == undefined ? "" : payload.dateFrom;
      state.print.dateTo = payload.dateTo == undefined ? "" : payload.dateTo;
      state.print.delivery_date =
        payload.delivery_date == undefined ? "" : payload.delivery_date;
    },
  },
  actions: {
    fetchDelivery({ commit, getters }, payload) {
      if (payload) {
        commit("FILTERED_DELIVERY", payload);
      }

      let deliveryList = axios({
        method: "GET",
        url: `/deliveries?page=${getters.filtered.page}&status=${getters.filtered.status}&datefrom=${getters.filtered.dateFrom}&dateto=${getters.filtered.dateTo}&delivery_date=${getters.filtered.delivery_date}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_DELIVERY_LIST", data.data.data);
          commit("FETCH_DELIVERY_HEADER", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("adm_tkn");
            return router.go("login");
          }
          commit("FETCH_DELIVERY_LIST", []);
          commit("FETCH_DELIVERY_HEADER", {});
        });

      return deliveryList;
    },
    clearFilterOrder(context) {
      context.commit("CLEAR_FILTER_ORDER");
    },
    async printDelivery({ getters, commit }, payload) {
      if (payload) {
        commit("PRINT_DELIVERY", payload);
      }
      let print = await axios({
        method: "GET",
        url: `/exportDeliveryManagement?datefrom=${getters.print.dateFrom}&dateto=${getters.print.dateTo}&delivery_date=${getters.print.delivery_date}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adm_tkn")}`,
        },
        responseType: "blob",
      })
        .then((res) => {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.ms-excel",
            })
          );
          if (getters.print.delivery_date != "") {
            const link = document.createElement("a");
            link.href = url;
            let date = getters.print.delivery_date.replace(/-/g, "");
            const filename = `${date}-配送管理.xlsx`;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          } else {
            const link = document.createElement("a");
            link.href = url;
            const filename = `配送管理.xlsx`;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((err) => {
          return err;
        });
      return print;
    },
  },
};

export default delivery_module;
